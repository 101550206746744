import { useQuery } from "react-query";

import CustomLoader from "../../components/CustomLoader";
import RenderTable from "../../components/RenderTable";
import queryParamConstant from "../../utils/queryParamConstant";
import columns from "./DatatableConfig";
import { getRoleUrl } from "../../apis/ManageRoles";
import CustomButton from "../../components/CustomButton";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const ManageRolesPage = () => {
  const [searchFilter, setSearchFilter] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [isFetchingSearch, setFetchingSearch] = useState(true);
  const { error, isLoading } = useQuery(
    queryParamConstant.GET_ROLES_URL,
    () =>
      getRoleUrl({
        term_category_code: "role_type",
        filter: {
          search_text: searchFilter,
          popup_type: "",
          status: "",
          device: "",
          data_range: "all",
          start_date: "",
          end_date: "",
        },
        page: 1,
        limit: 10,
        is_with_paginate: 0,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (list) => {
        setRoleList(list);
      },
    }
  );
  const refetchAll = async () => {
    setFetchingSearch(true);
    const response = await getRoleUrl({
      term_category_code: "role_type",
      filter: {
        search_text: searchFilter,
        popup_type: "",
        status: "",
        device: "",
        data_range: "all",
        start_date: "",
        end_date: "",
      },
      page: 1,
      limit: 10,
      is_with_paginate: 0,
    });
    setRoleList(response);

    setFetchingSearch(false);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      refetchAll();
      // Send Axios request here
    }, 800);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="relative mb-10 bg-white rounded-[10px] border-2 border-[#DADADA] py-6">
      {isLoading ? (
        <div className="h-[500px]">
          <CustomLoader />
        </div>
      ) : (
        <RenderTable
          tableData={roleList?.list}
          columns={columns}
          isLeftSideFilter={false}
          isFilter={false}
          isLoading={isFetchingSearch}
          isDateFilter={false}
          searchData={searchFilter}
          onSearchFilter={setSearchFilter}
          customButtonRight={
            <>
              <Link to="/roles/add">
                <CustomButton
                  title={
                    <div className="flex items-center">
                      Create New
                      <svg
                        className="ml-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.5 16C12.9183 16 16.5 12.4183 16.5 8C16.5 3.58172 12.9183 0 8.5 0C4.08172 0 0.5 3.58172 0.5 8C0.5 12.4183 4.08172 16 8.5 16ZM9.5 5C9.5 4.44772 9.05228 4 8.5 4C7.94772 4 7.5 4.44772 7.5 5V7H5.5C4.94772 7 4.5 7.44771 4.5 8C4.5 8.55228 4.94772 9 5.5 9H7.5V11C7.5 11.5523 7.94772 12 8.5 12C9.05228 12 9.5 11.5523 9.5 11V9H11.5C12.0523 9 12.5 8.55228 12.5 8C12.5 7.44772 12.0523 7 11.5 7H9.5V5Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  }
                  className="mt-0 px-4 h-[40px]"
                />
              </Link>
            </>
          }
        />
      )}
    </div>
  );
};
export default ManageRolesPage;
