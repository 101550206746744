/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import {
  getAutomatedFlowsDetail,
  getAutomatedFlowsList,
} from "../../apis/AutomatedFlows";
import CustomLoader from "../../components/CustomLoader";
import CustomSortFilter from "../../components/CustomSortFilter";
import RenderTable from "../../components/RenderTable";
import { autoFlowsDetailFilter } from "../../global/config";
import queryParamConstant from "../../utils/queryParamConstant";
import columns from "./DatatableConfig";

const AutomatedFlowsDetailPage = () => {
  const { pathname } = useLocation();
  const [selectedId, setSelectedId] = useState();
  const [selectedRange, setSelectedRange] = useState("all");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchData, setSearchData] = useState("");
  const [allDataList, setAllDataList] = useState([]);
  const [firstCall, setFirstCall] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [sortFilterData, setSortFilterData] = useState("all");
  const [perPage, setPerPage] = useState(10);
  const [startDateRange, setStartDateRange] = useState();
  const [isFetchingSearch, setFetchingSearch] = useState(false);
  const [endDateRange, setEndDateRange] = useState();
  const { data: automatedList, isLoading: isListLoading } = useQuery(
    queryParamConstant.GET_AUTOMATED_FLOWS_LIST_URL,
    () =>
      getAutomatedFlowsList({
        automated_flow_id: 1,
        filter: {
          search_text: "",
          popup_type: "",
          status: "",
          device: "",
          data_range: selectedRange,
          start_date: "",
          end_date: "",
        },
        page: 1,
        limit: 10,
        is_with_paginate: 1,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (automatedList) {
      const filter = automatedList?.list.find((item) =>
        pathname?.split("/")[2] === "sms-receipt"
          ? item?.category_name?.toLowerCase()?.includes("order & receipt")
          : pathname
              ?.split("/")[2]
              ?.replaceAll("-", " ")
              .includes(item?.category_name.toLowerCase().replaceAll("&", ""))
      );
      setSelectedId(filter?.automated_flow_id || 1);
      setTimeout(() => refetch(), 10);
    }
  }, [automatedList]);

  const { error, isLoading, refetch, isFetching } = useQuery(
    queryParamConstant.GET_AUTOMATED_FLOWS_OVERVIEW_URL,
    () =>
      getAutomatedFlowsDetail({
        automated_flow_id: selectedId,
        filter: {
          search_text: searchData,
          popup_type: "",
          status: sortFilterData,
          device: "",
          data_range: selectedRange,
          start_date:
            selectedRange === "custom"
              ? moment(startDateRange).format("YYYY-MM-DD")
              : "",
          end_date:
            selectedRange === "custom"
              ? moment(endDateRange).format("YYYY-MM-DD")
              : "",
        },
        page: page,
        limit: perPage,
        is_with_paginate: 1,
      }),
    {
      enabled: !!selectedId,
      refetchOnWindowFocus: false,
      onSuccess: (list) => {
        setTotal(list?.total);
        setFilterData(list?.list);
        setAllDataList(
          page === 1 ? [...list?.list] : [...allDataList, ...list?.list]
        );
      },
    }
  );
  const refetchAll = async () => {
    setFetchingSearch(true);
    if (selectedId) {
      const response = await getAutomatedFlowsDetail({
        automated_flow_id: selectedId,
        filter: {
          search_text: searchData,
          popup_type: "",
          status: sortFilterData,
          device: "",
          data_range: selectedRange,
          start_date:
            selectedRange === "custom"
              ? moment(startDateRange).format("YYYY-MM-DD")
              : "",
          end_date:
            selectedRange === "custom"
              ? moment(endDateRange).format("YYYY-MM-DD")
              : "",
        },
        page: page,
        limit: perPage,
        is_with_paginate: 1,
      });
      setTotal(response?.total);
      setFilterData(response?.list);
      setAllDataList([...allDataList, ...response?.list]);
    }
    setFetchingSearch(false);
  };
  useEffect(() => {
    if (!firstCall) {
      const delayDebounceFn = setTimeout(() => {
        refetchAll();
        // Send Axios request here
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchData]);
  useEffect(() => {
    if (startDateRange && endDateRange) {
      setTimeout(() => refetch(), 10);
    }
  }, [startDateRange, endDateRange]);

  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="relative mb-10 bg-white rounded-[10px] border-2 border-[#DADADA] py-6">
      {isLoading || isListLoading ? (
        <div className="h-[500px]">
          <CustomLoader />
        </div>
      ) : (
        <RenderTable
          isLoading={isFetching || isFetchingSearch}
          refetch={refetch}
          totalRows={total}
          page={page}
          perPage={perPage}
          setPerPage={setPerPage}
          setPage={setPage}
          allDataList={allDataList}
          tableData={filterData}
          columns={columns}
          isSearch={true}
          isFilter={false}
          startDateRange={startDateRange}
          endDateRange={endDateRange}
          setStartDateRange={setStartDateRange}
          setEndDateRange={setEndDateRange}
          searchData={searchData}
          setAllDataList={setAllDataList}
          onSearchFilter={(value) => {
            setFirstCall(false);
            setAllDataList([]);
            setSearchData(value);
            setPage(1);
          }}
          leftSideFilter={
            <CustomSortFilter
              totalData={total}
              selected={sortFilterData}
              onChange={(value) => {
                setAllDataList([]);
                setPage(1);
                setSortFilterData(value);
                setTimeout(() => refetch(), 10);
              }}
              options={autoFlowsDetailFilter}
            />
          }
          filterSelection={selectedRange}
          onFilterChange={(item) => {
            setAllDataList([]);
            if (item !== "custom") {
              setPage(1);
              setSelectedRange(item);
              setTimeout(() => refetch(), 10);
            } else {
              setPage(1);
              setSelectedRange(item);
            }
          }}
        />
      )}
    </div>
  );
};
export default AutomatedFlowsDetailPage;
