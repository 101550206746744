import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getSegmentsLists } from "../../apis/SegmentsLists";
import CustomLoader from "../../components/CustomLoader";
import RenderTable from "../../components/RenderTable";
import queryParamConstant from "../../utils/queryParamConstant";
import columns from "./DatatableConfig";

const SegmentsListsPage = () => {
  const navigate = useNavigate();
  const [selectedRange, setSelectedRange] = useState("all");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [allDataList, setAllDataList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [firstCall, setFirstCall] = useState(true);
  const [isFetchingSearch, setFetchingSearch] = useState(false);
  const [searchData, setSearchData] = useState("");
  const { error, isLoading, refetch, isFetching } = useQuery(
    queryParamConstant.GET_SEGMENTS_LIST_URL,
    () =>
      getSegmentsLists({
        filter: {
          search_text: searchData,
          popup_type: "",
          status: "",
          device: "",
          data_range: "all",
          start_date: "",
          end_date: "",
        },
        page: page,
        limit: perPage,
        is_with_paginate: 1,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (list) => {
        setTotal(list?.total);
        setFilterData(list?.list);
        setAllDataList(
          page === 1 ? [...list?.list] : [...allDataList, ...list?.list]
        );
      },
    }
  );
  const refetchAll = async () => {
    setFetchingSearch(true);
    const response = await getSegmentsLists({
      filter: {
        search_text: searchData,
        popup_type: "",
        status: "",
        device: "",
        data_range: "all",
        start_date: "",
        end_date: "",
      },
      page: page,
      limit: perPage,
      is_with_paginate: 1,
    });
    setTotal(response?.total);
    setFilterData(response?.list);
    setAllDataList([...allDataList, ...response?.list]);
    setFetchingSearch(false);
  };
  useEffect(() => {
    if (!firstCall) {
      const delayDebounceFn = setTimeout(() => {
        refetchAll();
        // Send Axios request here
      }, 800);

      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="relative mb-10 bg-white rounded-[10px] border-2 border-[#DADADA] py-6">
      {isLoading ? (
        <div className="h-[500px]">
          <CustomLoader />
        </div>
      ) : (
        <RenderTable
          isLoading={isFetching || isFetchingSearch}
          searchData={searchData}
          refetch={refetch}
          totalRows={total}
          page={page}
          perPage={perPage}
          setPerPage={setPerPage}
          setPage={setPage}
          allDataList={allDataList}
          tableData={filterData}
          columns={columns}
          isSearch={true}
          setAllDataList={setAllDataList}
          onSearchFilter={(value) => {
            setFirstCall(false);
            setAllDataList([]);
            setSearchData(value);
            setPage(1);
          }}
          isLeftSideFilter={false}
          isFilter={false}
          isDateFilter={false}
          filterSelection={selectedRange}
          onFilterChange={(item) => {
            setPage(1);
            setAllDataList([]);
            setSelectedRange(item);
            setTimeout(() => refetch(), 10);
          }}
          onRowClicked={(row) =>
            navigate("/segments/" + row?.id, {
              state: {
                id: row?.id,
                name: row?.name,
              },
            })
          }
        />
      )}
    </div>
  );
};
export default SegmentsListsPage;
