import { useState } from "react";
import CustomTabs from "../../components/CustomTabs";
import Details from "./Details";
import Members from "./Members";
import CampaignFlows from "./Campaigns";
import InformIcon from "../../assets/icons/InfomIcon";
import CustomDropdowns from "../../components/CustomDropdowns/CustomDropdowns";
import CustomSortFilter from "../../components/CustomSortFilter";
import { campaignFlowsFilter } from "../../global/config";
import Flatpickr from "react-flatpickr";

const SegmentsDetailsPage = () => {
  const [active, setActive] = useState(0);
  const [searchFilter, onSearchFilter] = useState("");
  const [filterSelection, onFilterChange] = useState("all");
  const [sortFilterData, setSortFilterData] = useState("all");
  const [startDateRange, setStartDateRange] = useState();
  const [endDateRange, setEndDateRange] = useState();
  const [dateRange, setDateRange] = useState();
  const [firstCall, setFirstCall] = useState(true);
  const [dateRangeValue, setDateRangeValue] = useState([
    startDateRange,
    endDateRange,
  ]);
  const handleSelectRange = (data) => {
    if (data === "custom") {
      setDateRange(data);
    } else {
      onFilterChange(data);
    }
  };

  return (
    <div className="">
      <div className="relative mb-10 bg-white rounded-[10px] border-2 border-[#DADADA] py-6">
        <div className="pb-2">
          <div className="flex justify-between w-full items-center px-10 pt-5 max-md:px-5">
            <CustomTabs
              active={active}
              onClick={(index) => {
                setActive(index);
                setFirstCall(true);
                onSearchFilter("");
                setSortFilterData("all");
                onFilterChange("all");
                setStartDateRange();
                setEndDateRange();
              }}
            />

            {active !== 0 && (
              <div className="flex flex-row flex-1 justify-end items-center">
                {" "}
                {active === 1 && (
                  <span data-tooltip-id="search-member">
                    <InformIcon />
                  </span>
                )}
                <div className="flex flex-col mx-3  py-px text-sm whitespace-nowrap bg-white text-neutral-500">
                  <div className="relative flex items-center ">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/c725e12ba0bda12d49b671469b893e3766bf88f5d15c7fadda99f8e08f3d5929?"
                      className="shrink-0 aspect-[1.06] fill-neutral-500 w-[17px] absolute ml-3"
                      alt=""
                    />
                    <input
                      value={searchFilter}
                      onChange={(e) => {
                        onSearchFilter(e.target.value);
                        setFirstCall(false);
                      }}
                      className="flex gap-2.5 h-[47px] pl-10 pr-3.5 py-2.5 rounded border border-gray-light border-solid w-[260px]"
                      placeholder="Search..."
                    />
                  </div>
                </div>
                {active !== 1 && (
                  <CustomSortFilter
                    isBorder={true}
                    selected={sortFilterData}
                    onChange={(value) => {
                      setSortFilterData(value);
                    }}
                    options={campaignFlowsFilter}
                  />
                )}
                {active !== 1 &&
                  (!dateRange ? (
                    <CustomDropdowns
                      isBgblue={false}
                      className={"h-[48px]"}
                      isCalender
                      onChange={(item) => handleSelectRange(item)}
                      selected={filterSelection}
                    />
                  ) : (
                    <Flatpickr
                      className="flex h-[47px] px-5 py-2.5 rounded border border-gray-light border-solid"
                      value={dateRangeValue}
                      placeholder="Select Custom Range"
                      options={{ mode: "range" }}
                      onChange={(date) => {
                        setDateRangeValue(date);
                        setStartDateRange(date[0]);
                        if (date.length > 1) {
                          setDateRange();
                          onFilterChange(dateRange);
                          setEndDateRange(date[1]);
                        }
                      }}
                    />
                  ))}
              </div>
            )}
          </div>
          {active === 0 ? (
            <Details />
          ) : active === 1 ? (
            <Members searchFilter={searchFilter} firstCall={firstCall} />
          ) : (
            <CampaignFlows
              searchFilter={searchFilter}
              filterSelection={filterSelection}
              sortFilterData={sortFilterData}
              startDateRange={startDateRange}
              endDateRange={endDateRange}
              firstCall={firstCall}
              setStartDateRange={setStartDateRange}
              setEndDateRange={setEndDateRange}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default SegmentsDetailsPage;
